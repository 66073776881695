import axios from "axios";

export const postContactForm = async (values) => {
  try {
    const { data } = await axios.post(
      "https://formsubmit.co/ajax/contact@lixium.dev",
      values
    );

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
