import React, { useEffect, useState } from "react";

import logoImg from "./assets/images/logo.svg";
import welcomeImg from "./assets/images/welcome.png";
import stage1Img from "./assets/images/stage1.png";
import stage2Img from "./assets/images/stage2.png";
import stage3Img from "./assets/images/stage3.png";
import greenArrowImg from "./assets/images/greenArrow.png";
import purpleArrowImg from "./assets/images/purpleArrow.png";
import reverseArrowImg from "./assets/images/reverseArrow.png";
// import project1Img from "./assets/images/project1.png";
// import project2Img from "./assets/images/project2.png";
import laurImg from "./assets/images/laur.jpg";
import nicuImg from "./assets/images/nicu.jpg";
import alexandrImg from "./assets/images/alexandr.jpeg";
import elenaImg from "./assets/images/elena.jpeg";
import instagramIcon from "./assets/icons/instagram.svg";
import menuIcon from "./assets/icons/menu.svg";
import arrowForwardIcon from "./assets/icons/arrow-forward.svg";

import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { postContactForm } from "./api/postContactForm";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  description: yup.string().required("Description is required"),
});

const App = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const onClickMenu = () => {
    setOpenMobileMenu((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setOpenMobileMenu(false);
  };

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      const element = document.querySelector(hash);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, []);

  return (
    <>
      <div id="top" />

      <header>
        <a href="#top">
          <img src={logoImg} alt="logo" />
        </a>

        <div className="navigation">
          <a href="#projects">Projects</a>

          <a href="#contact-us">Contact us</a>
        </div>

        <button className="navigation-mobile" onClick={onClickMenu}>
          <img src={menuIcon} alt="menu" />
        </button>
      </header>

      {openMobileMenu ? (
        <div className="nav-mobile">
          <a href="#projects" onClick={handleCloseMenu}>
            Projects
          </a>

          <a href="#contact-us" onClick={handleCloseMenu}>
            Contact us
          </a>
        </div>
      ) : null}

      <main className="container">
        <div className="welcome">
          <div className="welcome__description">
            <div>
              <h1>We build software.</h1>

              <p>
                We are a team of dedicated IT professionals, working as a full
                cycle software agency in a trusty and client-oriented
                environment
              </p>
            </div>

            <a href="#contact-us">
              <button className="button">Contact us</button>
            </a>
          </div>

          <img src={welcomeImg} alt="welcome" />
        </div>
        <div className="work">
          <h2>How we work</h2>

          <div className="work__section">
            <div className="stage">
              <img src={stage1Img} alt="stage" />

              <div>
                <h3>Project scoping</h3>

                <p>
                  A dedicated project manager understands the requirements by
                  communicating in a transparent way
                </p>
              </div>
            </div>

            <img src={greenArrowImg} alt="arrow" className="work__arrow" />

            <div className="stage">
              <img src={stage2Img} alt="stage" />

              <div>
                <h3>Design</h3>

                <p>
                  Prototyping and designing using the best User Experience
                  approaches
                </p>
              </div>
            </div>
          </div>

          <img
            src={purpleArrowImg}
            alt="arrow"
            className="work__arrow-center"
          />

          <div className="work__section">
            <div className="stage">
              <img src={stage3Img} alt="stage" />

              <div>
                <h3>Development</h3>

                <p>
                  A team of professional developers will abide by the needed
                  business requirements
                </p>
              </div>
            </div>

            <img src={reverseArrowImg} alt="arrows" className="work__arrow" />

            <div className="stage">
              <img src={stage3Img} alt="stage" />

              <div>
                <h3>Testing</h3>

                <p>
                  The Quality Assurance team will cover the product with
                  automated tests and make sure it is bug-free
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="projects" id="projects" style={{ marginBottom: 30 }}>
          <h2>Some of the projects our team worked on</h2>

          {/* <div className="projects__container">
            <div className="projects__section">
              <img src={project1Img} alt="project" />

              <div>
                <a
                  href="https://www.aeoncharge.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>Aeon Charge</h4>
                </a>

                <p>
                  Enables electric vehicle drivers to locate, activate, and pay
                  for any charging session with two simple taps, all on one app
                </p>
              </div>
            </div>

            <div className="projects__section">
              <img src={project2Img} alt="project" />

              <div>
                <a
                  href="https://www.donbest.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>Don Best</h4>
                </a>

                <p>
                  Leading global supplier of real-time betting data relevant to
                  North American sporting events
                </p>
              </div>
            </div>
          </div> */}
          <a href="presentation.pdf" target="_blank">
            <button className="button" style={{ padding: '25px 50px', display: 'flex', alignItems: 'center' }}>Explore our work
            <img src={arrowForwardIcon} alt="instagram" style={{ marginLeft: '10px' }} /></button>
          </a>
        </div>
        <div className="executives">
          <h2>Meet our executives</h2>

          <div className="executives__container">
            <div className="executives__section">
              <img src={laurImg} alt="CEO" />

              <div>
                <a
                  href="https://www.linkedin.com/in/laurvacarciuc"
                  target="_blank"
                  rel="noreferrer"
                >
                  Laur Vacarciuc
                </a>

                <p>CEO</p>

                <a
                  href="https://www.linkedin.com/in/laurvacarciuc"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </div>
            </div>

            <div className="executives__section">
              <img src={nicuImg} alt="CTO" />

              <div>
                <a href="https://www.linkedin.com/in/nicu-frumusache">
                  Nicu Frumusache
                </a>

                <p>CTO</p>

                <a href="https://www.linkedin.com/in/nicu-frumusache">
                  LinkedIn
                </a>
              </div>
            </div>

            <div className="executives__section">
              <img src={alexandrImg} alt="CEO" />

              <div>
                <a
                  href="https://www.linkedin.com/in/alexandr-alexandriuc-6832a230a"
                  target="_blank"
                  rel="noreferrer"
                >
                  Alexandr Alexandriuc
                </a>

                <p>Head of Business Development</p>

                <a
                  href="https://www.linkedin.com/in/alexandr-alexandriuc-6832a230a"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </div>
            </div>

            <div className="executives__section">
              <img src={elenaImg} alt="CEO" />

              <div>
                <a
                  href="https://www.linkedin.com/in/elena-fachira-180195fe"
                  target="_blank"
                  rel="noreferrer"
                >
                  Elena Fachira
                </a>

                <p>Business Development Manager</p>

                <a
                  href="https://www.linkedin.com/in/elena-fachira-180195fe"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="container" id="contact-us">
        <div className="info">
          <div>
            <h2>Let's work together</h2>

            <p>If you have a project in mind, feel free to contact us.</p>

            <p>
              Our Sales representative will contact you and schedule a demo
              call.
            </p>

            <a href="mailto:contact@lixium.dev">
              <h3>contact@lixium.dev</h3>
            </a>
          </div>

          <div>
            <a href="https://www.linkedin.com/company/lixium-solutions">
              LinkedIn
            </a>

            <a href="https://www.instagram.com/lixium.solutions">
              <img src={instagramIcon} alt="instagram" />
            </a>
          </div>
        </div>

        <Formik
          initialValues={{ name: "", email: "", description: "" }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await postContactForm(values);
          }}
        >
          {({ getFieldProps, isSubmitting }) => {
            return (
              <Form className="contact-form">
                <div>
                  <div className="contact-form__field">
                    <input
                      type="text"
                      placeholder="Name"
                      {...getFieldProps("name")}
                    />

                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="contact-form__field">
                    <input
                      type="email"
                      placeholder="Email"
                      {...getFieldProps("email")}
                    />

                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="contact-form__field">
                    <textarea
                      rows="8"
                      cols="50"
                      placeholder="Type your message here"
                      {...getFieldProps("description")}
                    />

                    <ErrorMessage
                      name="description"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <button
                  className="button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </footer>
    </>
  );
};

export default App;
